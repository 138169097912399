import { reactive, defineComponent } from "vue";
import { message } from "ant-design-vue";
import { loginValidate } from "@/hooks/component/loginValidate";
import { sendLoginVerifyCodeOld, resetPassword } from "@/apis/index";
import { LeftOutlined } from "@ant-design/icons-vue";
const MobileRegexp = /((\+?86-?)?1[3456789]\d{9})$/;
const PassRegexp = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}/;
export default defineComponent({
    name: "ResetPassword",
    components: {
        LeftOutlined,
    },
    methods: {
        handleCancel() {
            this.$router.back();
        },
        handleFinish(values) {
            resetPassword({
                mobile: values.mobile,
                verifycode: values.verifycode,
                password: values.password,
            }).then((res) => {
                if (res.errcode === 0) {
                    this.$router.push({
                        name: "Success",
                        params: {
                            mobile: this.formState.mobile,
                        },
                    });
                }
                else {
                    // 验证码失败
                    message.error(res.errmsg);
                }
            });
        },
        handleWaiting() {
            // 60秒倒计时
            this.waiting = true;
            this.timer = setInterval(() => {
                this.counter--;
                if (this.counter < 0) {
                    this.waiting = false;
                    this.sended = false;
                    this.counter = 60;
                    clearInterval(this.timer);
                }
            }, 1000);
        },
        // 获取阿里人机验证码
        getVerifyCode() {
            // 已经触发请求验证码，在没有返回结果前，再次点击无效。
            if (this.sended) {
                return;
            }
            if (this.formState.mobilePassed) {
                // 向阿里云服务器获取风控参数，随业务逻辑发送给业务服务器。
                const mobile = this.formState.mobile;
                window.nvc.getNVCValAsync((nvcVal) => {
                    this.sended = true;
                    // const script = document.createElement("script");
                    // script.src = `https://cf.aliyun.com/nvc/nvcAnalyze.jsonp?callback=registerRequest&a=${nvcVal}`
                    // document.body.append(script);
                    this.sendLoginVerify({ captcha: nvcVal });
                });
            }
            else {
                this.$refs.formRef.validateFields(["mobile"]);
            }
        },
        // 获取验证码
        sendLoginVerify(params) {
            sendLoginVerifyCodeOld({
                mobile: this.formState.mobile,
                ...params,
            })
                .then((res) => {
                this.isShow = true;
                if (res.errcode === 0) {
                    message.success("验证码发送成功，请注意手机短信。");
                    this.handleWaiting();
                }
                else if (res.errcode === 202400) {
                    this.isShow = false;
                    window.nvc.getNC({ renderTo: "registerNC" });
                }
                else {
                    message.error(res.errmsg || "系统异常");
                    this.initAwsc("login", this.sendLoginVerify);
                }
            })
                .finally(() => {
                this.sended = false;
            });
        },
        handleMobile(evt) {
            const value = evt.target.value;
            if (value.length < 12) {
                this.formState.mobile = value;
                this.formState.mobilePassed = MobileRegexp.test(value);
                // 短信验证码和手机号是相互绑定的，手机号码发送改变时输入的短信验证码作废
                this.formState.verifycode = "";
            }
            if (this.timer) {
                this.counter = 60;
                this.waiting = false;
                clearInterval(this.timer);
            }
        },
        handleCode(evt) {
            const value = evt.target.value;
            if (value.length < 7) {
                this.formState.verifycode = value;
            }
        },
        handlePassword(evt) {
            const value = evt.target.value;
            if (value.length < 17) {
                this.formState.password = value;
                this.formState.passwdPassed = PassRegexp.test(value);
            }
        },
    },
    data() {
        return {
            counter: 60,
            waiting: false,
            visible: false,
            sended: false,
            timer: null,
            isShow: true,
        };
    },
    setup() {
        const formState = reactive({
            mobile: "",
            verifycode: "",
            password: "",
            mobilePassed: false,
            passwdPassed: false,
        });
        const validateMobile = async (rule, value) => {
            if (!value) {
                formState.mobilePassed = false;
                return Promise.reject("请输入手机号码");
            }
            else if (MobileRegexp.test(value)) {
                formState.mobilePassed = true;
                return Promise.resolve();
            }
            else {
                formState.mobilePassed = false;
                return Promise.reject("请填写正确的手机号码");
            }
        };
        const validateCode = async (rule, value) => {
            if (!value) {
                return Promise.reject("请输入短信验证码");
            }
            else if (value.length !== 6) {
                return Promise.reject("验证码长度是6位");
            }
            else {
                return Promise.resolve();
            }
        };
        const validatePassword = async (rule, password) => {
            if (!password) {
                return Promise.reject("请输入密码");
            }
            else if (!PassRegexp.test(password)) {
                return Promise.reject("请输入6-16位密码，含数字和字母");
            }
            else {
                return Promise.resolve();
            }
        };
        const rules = {
            mobile: [{ validator: validateMobile, trigger: "blur" }],
            verifycode: [{ validator: validateCode, trigger: "blur" }],
            password: [{ validator: validatePassword, trigger: "blur" }],
        };
        const handleFailed = (errors) => {
            console.log(errors);
        };
        const { initAwsc } = loginValidate();
        return {
            formState,
            rules: rules,
            handleFailed,
            initAwsc,
        };
    },
    created() {
        const mobile = this.$route.params.mobile;
        if (mobile && MobileRegexp.test(mobile.trim())) {
            this.formState.mobile = mobile;
            this.formState.mobilePassed = true;
        }
        // 初始化
        this.initAwsc("login", this.sendLoginVerify);
    },
});
